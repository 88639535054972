import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Today, let me show you how to take pixel-perfect screenshots...just using your browser!`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/1zvLGf7pWjU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`There are multiple times in your daily job that you might take screenshots of a given website.`}</p>
    <p>{`Sometimes, you need the full-size version of the website.
Other times, you might want a pixel perfect version of a given element in that website.`}</p>
    <p>{`What if you want to take a screenshot of the navbar or that cute button that you found on a website?`}</p>
    <p>{`One option could be to add one (or more) of these Chrome Extensions that let you take screenshots of a given website.
Most of the time, they only consider one of the use cases, so you might end up with multiple extensions in your browser.`}</p>
    <p>{`Well...`}</p>
    <b>
What if I tell you that you don't need any extensions for taking pixel-perfect screenshots and the tools you need are already available in your browser?
    </b>
    <p>{`Yes, your browser has already the tools you need.`}</p>
    <p>{`Let me show you how you can do it using your Chrome DevTools.`}</p>
    <ol>
      <li parentName="ol">{`Open the Chrome DevTools`}</li>
      <li parentName="ol">{`Open the Command Menu in the Chrome DevTools`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--ci3p-7mY--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/o2yku46lj1wh965w4b4v.png",
        "alt": "Image"
      }}></img></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Write "Screenshot": some options will appear to be selected.`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--vyeoT1Ig--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/nylf9s00kzqxy8dm16by.png",
        "alt": "Image"
      }}></img></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select Capture full-size screenshot or Capture Node Screenshot based on if you want the screenshot of the entire webpage or just an element of it.`}</li>
    </ol>
    <p>{`That's it! No third-party extensions, no downloads, just your browser and you can take pixel-perfect screenshots!`}</p>
    <p>{`I hope this video was useful to you and if so, click that like button and subscribe to my Youtube Channel! <3`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/1zvLGf7pWjU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      